import cx from 'classnames'
import { Fragment, ReactNode } from 'react'

import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import $$ from './data-wiki.module.css'

interface Operand {
  value: string | number
  description: string | ReactNode
}
interface CalculationBoxProps {
  title?: string
  operands: Operand[]
  operators: string[]
  loading?: boolean
}

export const CalculationBox = ({
  title = 'Calculation',
  operands,
  operators,
  loading = true,
}: CalculationBoxProps) => {
  if (loading) {
    return (
      <div className={cx($$.calculationBox, 'flex items-center justify-center h-[164px]')}>
        <BeamLoadingIndicator />
      </div>
    )
  }
  return (
    <section className={$$.calculationBox}>
      <h3 className={'m-0 mb-2'}>{title}</h3>
      <div className={'flex flex-row text-center items-start justify-evenly'}>
        {operands.map((operand, index) => {
          const nextOperator = operators[index]
          const isLastOperand = index === operands.length - 1
          return (
            <Fragment key={`${operand.value}-${index}`}>
              <div className={cx('flex flex-col justify-center', { 'font-black': isLastOperand })}>
                <div
                  className={cx('font-primary text-sky-800 desktop:text-[24px]', $$.operandValue)}>
                  {operand.value}
                </div>
                <div
                  className={cx('text-md', {
                    'font-secondary text-black': !isLastOperand,
                    'font-primary text-sky-800': isLastOperand,
                  })}>
                  {operand.description}
                </div>
              </div>

              {!!nextOperator && (
                <div className={cx('desktop:text-[24px] font-secondary px-2 font-normal')}>
                  {nextOperator}
                </div>
              )}
            </Fragment>
          )
        })}
      </div>
    </section>
  )
}
