import { TChainNonprofit } from './types/ChainNonprofit'
import { TDonationType } from './types/DonationType'
import { WidgetName } from './types/WidgetNames'

export type Maybe<T> = T | null | undefined

export enum EUserType {
  Admin = 'Admin',
  Engineering = 'Engineering',
  Super = 'Super',
  Executive = 'Executive',
  Marketing = 'Marketing',
  Finance = 'Finance',
  Staff = 'Staff',
}

export interface UserFeatureFlag {
  key: string
  active: boolean
}

export interface UpcomingPromosInfo {
  campaignName: string | undefined
  startTime: Date
  daysUntilLive: number
}

export enum AccountType {
  internal = 'internal',
  external = 'external',
}

// Partner Portal session user
export interface TUser {
  autopayModalSeenCount: number
  canSeeCumulativeImpactCTA: boolean
  chainId: number | null
  cumulativeImpactRequest: boolean
  email: string
  firstName?: string
  id: string
  lastName?: string
  partner: {
    name: string
    usePpgf: boolean
    ppgfStatus: string
    launchDate: Date | string | null
    logo?: string
    canSeeAutopayFeatures: boolean
    invoiceEmail: string
    invoiceCcEmails: string[]
    offersSubscriptionProduct: boolean
    inKindDonations: { displayInKindDonations: boolean; donationName: string }
    redemptionsInLastSevenDays: number | null
  }
  partnerId: number
  type: keyof typeof EUserType
  useTwoFactor: boolean
  featureFlags?: UserFeatureFlag[]
  upcomingPromos?: UpcomingPromosInfo[]
  accountType: AccountType | null
  /**
   * twoFactorToken isn't technically part of the user object, but during 2FA we send a temporary object
   * with only `id` and `twoFactorToken`. Including it in the TUser interface facilitates doing conditional checks
   * between these states instead of typecasting two separate interfaces.
   */
  twoFactorToken?: string
}

export interface TSite {
  name: string
  storeId: number | null
}

export interface BeamDropdownOption {
  display: string
  value: any
}

export type TNonprofit = {
  actionLink?: string
  badge?: string
  causeId?: number
  causeNonprofits?: TCauseNonprofit[]
  communityPhoto?: string
  createdAt?: Date | string
  description?: string
  howToHelpBeyondBeam?: string
  donationLink?: string
  id?: number
  isDeleted?: boolean
  mission?: string
  name: string
  oldSdkId?: number
  personalPhoto?: string
  pointOfContactEmail?: string
  pointOfContactName?: string
  pointOfContactPhone?: string
  pointOfContactTitle?: string
  ppgfId?: string
  ppgfLink?: string
  regions?: string[]
  sendgridListId?: string
  updatedAt?: Date | string
  website?: string
}

export type TCauseNonprofit = {
  active?: boolean
  cause?: TCause
  causeDisplayIcon?: string
  causeDisplayName?: string
  causeId: number
  causeIdNonprofitId?: string
  causeSelectedImage?: string
  chainDonated: number
  chainId: number
  chainIdNonprofitId?: string
  createdAt?: Date | string
  filters?: string[]
  id?: number
  matchDonated: number
  nonprofit?: TNonprofit
  nonprofitId: number
  oldSdkId?: number
  selectedHexColor?: string
  sort_order?: number // for backwards compatability with sdk backend
  sortOrder?: number
  updatedAt?: Date | string
}

export type TCause = {
  id?: number
  name: string
  logo: string | null
  selectedImage?: string
  image?: string
  description?: string
  oldSdkId?: number
  createdAt?: Date | string
  updatedAt?: Date | string
}

// Partner Portal Partner
export interface TPPPartner {
  chainId: number
  donateForUnredeemed: boolean
  id: number
  inKindDonations: boolean
  invoiceCcEmails: string[]
  invoiceEmail: string | null
  invoiceFirstName: string | null
  invoiceLastName: string | null
  invoiceMinimum: number | null
  isActive: boolean
  launchDate: string | null
  liveInCbe: boolean
  locations: number
  name: string
  onDiscovery: boolean
  paymentStructure: TPaymentStructure | null
  ppgfEmail: string | null
  transactionVerificationRequired: boolean
  usePpgf: boolean
}

export interface TCBPartner {
  address1?: string
  address2?: string
  chainNonprofits?: Array<TChainNonprofit>
  city?: string
  communityImpactLink?: string
  countryCode?: string
  createdAt?: Date | string
  donationType?: TDonationType
  donationTypeId?: number
  email?: string
  emailServiceProvider?: string
  espEpiKey?: string
  id?: number
  isDeleted?: boolean
  isReseller?: boolean
  jsonReports?: TJsonReport[]
  logoImage?: string
  marketingImage?: string
  matchDonationType?: TDonationType
  matchDonationTypeId?: number
  matchUnlockPercentage?: number
  name: string
  oldSdkId?: number
  paymentStructure?: TPaymentStructure
  phone?: string
  randomizeNonprofitOrder?: boolean
  rectLogoImage?: string
  sendgridListId?: string
  state?: string
  updatedAt?: Date | string
  useFavoriteNonprofit?: boolean
  website?: string
  zipCode?: string
}

// Payment
export interface TPaymentStructure {
  id: number
  name: string
  isLocationBased: boolean
}

export type InvoiceTypes = 'all' | 'monthly' | 'quarterly' | 'yearly' | null

// Invoices
export interface TInvoice {
  beamInvoiceLink: string | null
  createdAt: Date | string
  date: string | Date
  description: string
  dueDate: string | Date
  endDate: string | Date
  id: string
  isPaid: boolean | null
  lineItems?: Array<TLineItem | never>
  month?: TMonthAttributes
  monthId: string | null
  name: string | null
  nonprofit: string | null
  nonprofitLink: string | null
  paidOn: string | Date | null
  partiallyPaid: boolean
  partnerId: number
  partnerInvoiceLink: string | null
  payPalId: string | null
  paymentFailed: boolean
  paymentRequested: boolean | null
  quarterId: string | null
  scheduled: boolean
  startDate: string | Date
  type: InvoiceTypes
  updatedAt: Date | string
}

export interface TLineItem {
  amount: number
  createdAt: Date | string
  date: string | Date
  description: string
  error: string | null
  id: string
  invoiceId: string
  isPaid: boolean | null
  linkToPay: string | null
  name: string
  nonprofit: string | null
  orderId: string | null
  payable: boolean | null
  ppgfResponseObject: any | null
  quantity: number | null
  referenceId: string | null
  status: string | null
  unitPrice: number | null
  updatedAt: Date | string
}

export interface TMonthAttributes {
  beamRepeatCustomers: number
  beamSpending: number
  beamTransactions: number
  beamUniqueCustomers: number
  createdAt: Date | string
  donationsValue: number
  endDate: string | Date
  id: string
  matchDonations: number
  month: number | null
  name: string
  nonBeamRepeatCustomers: number
  nonBeamSpending: number
  nonBeamTransactions: number
  nonBeamUniqueCustomers: number
  partnerId: number
  quarterId: string
  reportId: string
  shopifyConversionRate: number | null
  startDate: string | Date
  type: string
  uniqueNonprofitSelections: number
  updatedAt: Date | string
  yearId: string
  yearNumber: number | null
}

// Reports
export interface TChildReport {
  id: string
  chainId: number
  partnerReportId: string
  aggregateReportId: string
  reportPeriodId: string
  name: string
  reportIdentifier: string
  reportNumber: number
  reportType: string
  reportTimePeriod: string
  beamTransactions: string
  nonBeamTransactions: string
  beamSpending: string
  nonBeamSpending: string
  beamUniqueCustomers: string
  nonBeamUniqueCustomers: string
  beamRepeatCustomers: string
  nonBeamRepeatCustomers: string
  startTime: string
  endTime: string
  donationsValue: string
  matchDonations: string
  stores: { [index: string]: StoreReport }
  causes: { [index: string]: CauseReport }
  nonprofits: { [index: string]: NonprofitReport }
  uniqueNonprofitSelections: string
  inKindDonations?: null
  showCauses: boolean
  shopifyConversionRate: number
  usedForBilling: boolean
  createdAt: string
  updatedAt: string
  reportPeriodChainId?: null
  reportPeriod?: null
}

export interface TReport {
  averageOrderValue?: string
  averageOrderValueBeam?: number
  averageOrderValueTraditional?: number
  averageOrderFrequency?: string
  averageOrderFrequencyBeam?: number
  averageOrderFrequencyTraditional?: number
  liftFromIncreasedFrequency?: number
  liftFromIncreasedAOV?: number
  redemptionRate?: number
  customersUsingBeam?: number

  beamRepeatCustomers: number
  beamSpending: number
  beamTransactions: number
  beamUniqueCustomers: number
  buckets: { [index: string]: TChildReport }
  causes: { [index: string]: CauseReport }
  donationsValue: number
  endDate: string
  endTime: string
  id: string
  inKindDonations: boolean
  matchDonations: number
  months?: Array<TMonthReport>
  name: string
  nonBeamRepeatCustomers: number
  nonBeamSpending: number
  nonBeamTransactions: number
  nonBeamUniqueCustomers: number
  nonprofits: { [index: string]: NonprofitReport }
  quarters?: Array<TQuarterReport>
  showCauses: boolean
  startDate: string
  startTime: string
  stores: { [index: string]: StoreReport }
  type: string
  uniqueNonprofitSelections: number
  yearNumber: number
  years?: Array<TYearReport>
}

export interface CauseReport {
  store: string
  transactions: number
  donationsValue: number
  uniqueCustomers: number
}

export interface StoreReport {
  causes: { [index: string]: CauseReport }
  nonprofits: { [index: string]: NonprofitReport }
  beamSpending: number
  donationsValue: number
  nonBeamSpending: number
  beamTransactions: number
  beamRepeatCustomers: number
  beamUniqueCustomers: number
  nonBeamTransactions: number
  nonBeamRepeatCustomers: number
  nonBeamUniqueCustomers: number
}

interface NonprofitReport extends CauseReport {
  cause: string
}

export interface TMonthReport extends TReport {
  month?: number
  partnerReportQuarterId: string
  partnerReportYearId: string
  partnerReportId: string
}

export interface TQuarterReport extends TReport {
  months: Array<TMonthReport>
  quarterNumber?: number
  partnerReportYearId: string
  partnerReportId: string
}

export interface TYearReport extends TReport {
  quarters: Array<TQuarterReport>
  partnerReportId: string
}

export interface TJsonReport {
  id: string
  report: TReport
  partnerId: number
}

export interface TRemoteConfig {
  id: string
  widgetName: WidgetName
  version: string
  config: {
    live: {
      mobile: {
        title?: string
        description?: string
      }
      web: {
        title?: string
        description?: string
        theme: { [key: string]: string }
      }
    }
    draft: {
      mobile: {
        title?: string
        description?: string
      }
      web: {
        title?: string
        description?: string
        theme: { [key: string]: string }
      }
    }
  }
}

export interface CampaignPromoBody {
  boostAmount: number
  boostStatus: string
  boostedNonprofits: string
  boostedStoreNonprofitIds: number[]
  campaignName: string
  endDate: string
  id: string
  startDate: string
  edit: string
  chainId: number | null
  promoText: string | null
  availableToWholeChain: boolean
  boostAllNonprofits: boolean
  color: string
}
export interface CampaignPageBody {
  completedPromos: CampaignPromoBody[]
  completedPromosCount: number
  currentPromos: CampaignPromoBody[]
  currentPromosCount: number
}

export interface PartnerUploadBody {
  id: string
  chainId: number
  fileName: string
  s3ObjectPath: string
  s3BucketName: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export interface PartnerDiscountsBody {
  chainId: number
  discountId: string
  discountPercent: number
  endDate: string
  externalDiscountReason: string | null
  internalDiscountReason: string
  reportPeriodId: string
  reportPeriodName: string
  startDate: string
  status: 'pending' | 'applied' | 'deleted'
}

export interface AdminDiscountsBody extends PartnerDiscountsBody {
  createdAt: string
  createdBy: string | null
  deletedAt: string | null
  deletedBy: string | null
  deletedReason: string | null
}

// ================== Partner Impact ==================

export interface OverviewNonprofitImpactObject {
  name: string
  cause: string
  active: boolean
  personalPhoto: string
  communityPhoto: string
  donationLink: string
  actionLink: string
  ppgfLink: string
  pointOfContactName: string
  pointOfContactEmail: string
  pointOfContactPhone: string
  pointOfContactTitle: string
  target_image: string
  regions: string[]
  id: number
  isTrending: boolean
  impact: {
    total_donated: number
    target_donation_amount: number | null
  }
  storeNonprofit?: number | null
  associatedStores: {
    storeId: number
    name: string
  }[]
}

export interface OverviewStoreNonprofitInfo {
  isActive: boolean
  nonprofitId: number
  nonprofitName: string
  storeId: number
  storeName: string
  storeNonprofitId: number
}

export interface OverviewPartnerImpactResponse {
  nonprofits: OverviewNonprofitImpactObject[]
  storeNonprofits: OverviewStoreNonprofitInfo[]
}
