import cx from 'classnames'

import { BeamLoadingIndicator } from '../../stories/BeamLoadingIndicator'

const DEFAULT_LOADING_INDICATOR_COLOR = 'coral-500'

export function LoadingPage({ color = DEFAULT_LOADING_INDICATOR_COLOR }) {
  return (
    <div className={'relative'}>
      <LoadingOverlay isTransparent={false} color={color} />
    </div>
  )
}

export function LoadingOverlay({ isTransparent = false, color = DEFAULT_LOADING_INDICATOR_COLOR }) {
  return (
    <div
      style={{
        backgroundBlendMode: 'lighten',
        background: isTransparent ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 1.0)',
      }}
      className={cx('absolute top-0 left-0 right-0 bottom-0 z-10')}>
      <div className={'flex flex-col justify-center items-center h-[90vh]'}>
        <BeamLoadingIndicator color={color} />
      </div>
    </div>
  )
}
