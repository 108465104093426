import { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { BeamLogo } from '../../../stories/BeamLogo'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CenteredContent } from '../CenteredContent'
import { LoadingPage } from '../LoadingPage'

interface ConnectRequestResponse {
  firstInstall: boolean
}

export const ShopifyAuthorizePage = () => {
  const [installStatus, setInstallStatus] =
    useState<'first_install' | 'already_installed' | 'fail' | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axiosRequest('POST', `${getV2ApiUrl()}/shopify-public-app/connect`)
        const data = res?.data as ConnectRequestResponse
        setInstallStatus(data?.firstInstall ? 'first_install' : 'already_installed')
      } catch (error: any) {
        setInstallStatus('fail')
      }
    })()
  }, [])

  if (installStatus === 'first_install') {
    return <PostSubmissionMessage />
  }
  if (installStatus === 'already_installed') {
    return <Redirect to={`${REDESIGN_ROOT_PATH}/`} />
  }
  if (installStatus === 'fail') {
    return <PostSubmissionMessage isFailure />
  }

  return <LoadingPage />
}

const PostSubmissionMessage = ({ isFailure = false }: { isFailure?: boolean }) => (
  <CenteredContent useVH>
    <div className={'flex flex-col items-center text-center w-1/3'}>
      <div className={'mb-4'}>
        <BeamLogo />
      </div>
      <h2 className={'font-secondary'}>
        {isFailure
          ? 'There was a problem linking your Beam account to your Shopify app. Please contact a Beam representative for support.'
          : 'Your Shopify App was successfully linked to your Beam partner account.'}
      </h2>
      <Link to={`${REDESIGN_ROOT_PATH}/`}>Go Home</Link>
    </div>
  </CenteredContent>
)
