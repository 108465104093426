import { useContext } from 'react'

import { BeamButton } from '../../../../stories/BeamButton'
import { useGtagWithContext } from '../../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { TimeframeToggleContext } from '../TimeframeToggleProvider'

export function TimeframeViewToggles() {
  const { timeframeView, setTimeframeView } = useContext(TimeframeToggleContext)
  const gtag = useGtagWithContext()
  const oneMonthLabel = '1 Month View'
  const sixMonthLabel = '6 Month Impact'
  return (
    <div className="flex space-x-1 items-baseline">
      <BeamButton
        label={oneMonthLabel}
        className={'w-min'}
        variant={timeframeView === 'oneMonth' ? 'basic' : 'input'}
        onClick={() => {
          setTimeframeView('oneMonth')
          gtag(`Overview | Clicked ${oneMonthLabel} button`)
        }}
      />
      <BeamButton
        label={'6 Month Impact'}
        className={'w-min'}
        variant={timeframeView === 'sixMonth' ? 'basic' : 'input'}
        onClick={() => {
          setTimeframeView('sixMonth')
          gtag(`Overview | Clicked ${sixMonthLabel} button`)
        }}
      />
    </div>
  )
}
