import { useBeamSelector } from '../../../hooks'
import { TUser } from '../../../utils/types'
import { baseGtag } from './BeamGoogleAnalytics'

/**
 *  The value that will appear as the event action in Google Analytics Event reports.
 */
type GAEventAction = string

/**
 * Values will appear as "(not set)" for the first 48 hours after creating a custom metric in GA.
 */
export interface GAEventMetadata {
  chainId: number | null
  beam_user_id: string | null
  [key: string]: any
}

/**
 * Log an event to Google Analytics
 * @docs https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @param {string} event - event name, such as "login"
 * @param {object} metadata - object of additional fields to send to GA
 */
export function gtag(event: GAEventAction, metadata?: GAEventMetadata): void {
  baseGtag('event', event, metadata || {})
}

/**
 * React hook that returns the `gtag` function with `chainId` and `beam_user_id` always included in events.
 *
 * @example
 * const gtag = useGtagWithContext()
 * gtag('eventName', { siteId: site.id })
 */
export function useGtagWithContext() {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  return (event: GAEventAction, metadata: Record<any, any> = {}) => {
    gtag(event, {
      beam_user_id: user?.id || '',
      chainId: user?.chainId || null,
      ...metadata,
    })
  }
}

export function getGtagTrackingId() {
  // Update the following variable to tailor to your local GA account
  // (it would be noisy if we're all hitting the same instance)
  return process.env.REACT_APP_GA_TRACKING_ID ||
    window.location.hostname === 'partner-portal.beamimpact.com'
    ? 'G-P1Y67G1HCS' // Production only
    : 'G-NLJ0H2B1H1' // For all environments
}
