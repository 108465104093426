import cx from 'classnames'
import { isNil, round } from 'lodash'
import { useEffect, useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { dollarFormat } from '../../../utils/root'
import { Maybe, TSite, TUser } from '../../../utils/types'
import { UpDownIcon } from '../../root/iconComponents/UpDown'
import {
  getHasBrandSpecificConversionPercent,
  shouldDisplayAnyOneTimePurchaseMetrics,
  shouldDisplayAnySubscriptionMetrics,
} from '../OverviewPage/BusinessImpactModule/BusinessImpactModule.helpers'
import { fetchOptimalRoiWithSubscriptions } from '../OverviewPage/Overview.api'
import { OptimalRoiWithSubscriptionsResponse } from '../OverviewPage/OverviewPage.types'
import { BeamMetricInputBox, MetricInputTypes } from './BeamMetricInputBox/BeamMetricInputBox'
import { CalculationBox } from './CalculationBox'
import { CalculationTable } from './CalculationTable/CalculationTable'
import $$ from './data-wiki.module.css'

const SIX_MONTH_DURATION_VALUE = 6

interface RoiResponseWithLoadingState {
  data: OptimalRoiWithSubscriptionsResponse | null
  loading: boolean
  error: string | null
}

function toPercent(input: Maybe<number>, defaultValue = 0) {
  if (isNil(input)) {
    return `${defaultValue}%`
  }

  const pctValue = input * 100
  return `${round(pctValue)}%`
}

export const DataWikiPage = () => {
  const [roiResponse, setRoiResponse] = useState<RoiResponseWithLoadingState>({
    data: null,
    loading: true,
    error: null,
  })
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const site: TSite | undefined = useBeamSelector(({ site }) => site)

  const oneMonthMetrics = roiResponse.data?.oneMonthMetrics.optimalMetrics || null
  const dataWikiData = roiResponse.data?.dataWiki || null
  const hasBrandSpecificConversionPercent = getHasBrandSpecificConversionPercent(dataWikiData)
  const isSubscriptionOnly: boolean =
    !isNil(oneMonthMetrics) &&
    shouldDisplayAnySubscriptionMetrics(oneMonthMetrics) &&
    !shouldDisplayAnyOneTimePurchaseMetrics(oneMonthMetrics)

  useEffect(() => {
    if (!user?.partnerId) {
      return
    }

    setRoiResponse(prev => ({
      data: prev.data,
      loading: true,
      error: prev.error,
    }))
    ;(async () => {
      try {
        const response = await fetchOptimalRoiWithSubscriptions(
          user?.partnerId,
          site?.storeId || undefined
        )
        setRoiResponse({
          data: response,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        console.error(error)
        setRoiResponse({
          data: null,
          loading: false,
          error: 'There was an error fetching your ROI data.',
        })
      }
    })()
  }, [site?.storeId, user?.partnerId])

  return (
    <article className={cx($$.dataWikiPageContainer, 'pt-14')}>
      <div className={cx($$.paragraphBlock, 'mb-11')}>
        <h1 className={'m-0 mb-2'}>Subscription Data Dictionary</h1>
        <p>
          This page outlines how we calculate the incremental first subscriptions the Beam
          integration has driven and the sales lift this would drive in a 6 month period, taking
          into account likely subscriber churn.
        </p>
      </div>
      <h2>Subscriber Signup Lift</h2>
      <div className={cx($$.paragraphBlockGroup)}>
        <div className={$$.paragraphBlock}>
          <h3>What is Subscriber Sales Lift? </h3>
          <p>
            Subscriber Signup Lift is an estimate of the incremental first subscriptions the Beam
            integration helps drive by building increased brand loyalty. The Baseline number of
            subscriptions estimates the number of new subscribers your site would have had without
            the Beam integration.
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>How do we calculate it? </h3>
          <p>
            To calculate we measure subscriber conversion for customers who selected a nonprofit and
            deduct the number who added to cart without engaging with the Beam integration. To
            account for any selective engagement bias, we then multiply by an{' '}
            {hasBrandSpecificConversionPercent
              ? 'AB Test adjustment factor that is specific to your brand'
              : 'industry standard AB Test adjustment factor'}
            .
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.metric,
              label: 'Beam First Subscription CCR',
              value: toPercent(dataWikiData?.beamFirstSubscriptionCcr),
              tooltip: `Orders with an in-cart nonprofit selection ID that are tagged as First Time Subscription Orders divided by Beam carts (the total number of carts with 1 or more items generated for this time period with a nonprofit selection ID)`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Non-Beam First Subscription CCR',
              value: toPercent(dataWikiData?.nonBeamFirstSubscriptionCcr),
              tooltip: `First time subscription orders with no nonprofit selection divided by the total number of non-Beam carts (calculated by deducting carts with a nonprofit selection from total carts created)`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.header,
              label: hasBrandSpecificConversionPercent
                ? `From ${user?.partner.name}`
                : 'Industry Benchmark',
            },
            {
              type: MetricInputTypes.metric,
              label: 'AB Test Discount Factor',
              value: hasBrandSpecificConversionPercent
                ? toPercent(dataWikiData?.abTestDiscountFactor)
                : toPercent(dataWikiData?.estimatedGlobalAbTestDiscountFactor),
              tooltip: `To account for any selective engagement bias, we use an AB Test discount factor`,
            },
          ]}
        />
      </div>
      <div className={'mt-7 pb-4'}>
        <CalculationBox
          loading={roiResponse.loading}
          operands={[
            {
              value: '( ' + toPercent(oneMonthMetrics?.beamFirstSubscriptionCcr),
              description: 'Beam Subscription CCR',
            },
            {
              value: toPercent(oneMonthMetrics?.nonBeamFirstSubscriptionCcr) + ' )',
              description: 'Non-Beam Subscription CCR',
            },
            {
              value: toPercent(oneMonthMetrics?.nonBeamFirstSubscriptionCcr),
              description: 'Non-Beam Subscription CCR',
            },
            {
              value:
                '( 1 - ' +
                (hasBrandSpecificConversionPercent
                  ? toPercent(dataWikiData?.abTestDiscountFactor)
                  : toPercent(dataWikiData?.estimatedGlobalAbTestDiscountFactor)) +
                ' )',
              description: 'AB Test Discount Factor',
            },
            {
              value: toPercent(oneMonthMetrics?.subscriptionSignUpLift || null),
              description: 'Subscriber \n' + 'Signup Lift',
            },
          ]}
          operators={['-', '/', 'X', '=']}
        />
      </div>
      <h2>Incremental First Subscriptions Attributed to Beam</h2>
      <div className={$$.paragraphBlockGroup}>
        <div className={$$.paragraphBlock}>
          <h3>What is Incremental First Subscriptions Attributed to Beam? </h3>
          <p>
            Incremental New Subscribers are the number of customers that converted to be subscribers
            due to the Beam integration building increased brand loyalty.
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>How do we calculate it? </h3>
          <p>
            Out of the total new subscribers in the period of the ROI report, we want to estimate
            the portion that converted due to the Beam integration. To do this, we use our
            Subscriber CCR Lift % to estimate the incremental new subscribers that we would
            attribute to Beam.
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.description,
              text: 'Here are the provided metrics we use to calculate Incremental subscriptions attributed to Beam',
            },
            {
              type: MetricInputTypes.metric,
              label: 'Total First Subscriptions',
              value: (oneMonthMetrics?.totalSubscriptionFirstOrders || 0).toString(),
              tooltip: `The total number of initial subscription orders placed`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Subscriber Signup Lift',
              value: toPercent(oneMonthMetrics?.subscriptionSignUpLift || null),
              tooltip: `Estimation of incremental first subscriptions Beam drives calculated by deducting Non-Beam First Subscription CCR from Beam First Time Subscription CCR and multiplying by a discount factor to account for selective engagement bias`,
              icon: <UpDownIcon />,
            },
          ]}
        />
      </div>

      <div className={cx($$.paragraphBlock, 'pt-8')}>
        <h3>How does this compare with the number of new subscribers without Beam?</h3>
        <p>
          The <strong className={$$.paragraphHighlight}>Baseline Subscribers</strong> is the number
          of new subscribers your brand would have if the subscriber signup was the same as without
          the Beam integration. Given that we have already calculated the incremental first
          subscriptions attributed to Beam, we can use the total number of new subscribers minus
          those that we attribute to Beam to estimate the number of subscribers we would have
          expected to see without the Beam integration.{' '}
        </p>
      </div>

      <div className={'mt-5'}>
        <CalculationBox
          title={'Calculation for Baseline Subscribers'}
          loading={roiResponse.loading}
          operands={[
            {
              value: oneMonthMetrics?.totalSubscriptionFirstOrders || 0,
              description: 'Total First Subscriptions',
            },
            {
              value: '( 1',
              description: '',
            },
            {
              value: toPercent(oneMonthMetrics?.subscriptionSignUpLift) + ' )',
              description: 'Subscriber Signup Lift',
            },
            {
              value: round(Number(oneMonthMetrics?.baselineFirstSubscriptions)) || 0,
              description: 'Baseline Subscribers',
            },
          ]}
          operators={['/', '+', '=']}
        />
      </div>

      <div className={'pt-4 pb-5'}>
        <CalculationBox
          title={'Calculation for Incremental Subscribers Drive by Beam'}
          loading={roiResponse.loading}
          operands={[
            {
              value: oneMonthMetrics?.baselineFirstSubscriptions || 0,
              description: 'Baseline Subscribers',
            },
            {
              value: toPercent(oneMonthMetrics?.subscriptionSignUpLift),
              description: 'Subscriber Signup Lift',
            },
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Attributed to Beam',
            },
          ]}
          operators={['X', '=']}
        />
      </div>

      <h2>6 Month Sales Lift from Subscriber Signup Lift </h2>
      <div className={$$.paragraphBlock}>
        <h3>How do we think about the revenue impact of additional subscriptions? </h3>
        {isSubscriptionOnly ? (
          <p>
            We take your brand’s 6 month subscription LTV and multiply by the the incremental
            subscribers attributed to Beam. Because the additional LTV for subscribers accrues over
            a 6-month time horizon, we calculate sales lift for each monthly cohort. We adjust the
            total Incremental LTV based on the number of months that a cohort has been active so
            that we only account for sales lift that your brand has already realized.
          </p>
        ) : (
          <>
            <p>
              We start by estimating the additional lifetime value (LTV) from subscribers, by
              comparing against your brand specific data regarding 6 month LTV for subscribers vs.
              one time purchase orders. These inputs already account for churn, and by subtracting
              one time purchase LTV from subscription LTV, we calculate the{' '}
              <strong className={$$.paragraphHighlight}>Incremental LTV</strong> of converting a
              customer to a subscription.
            </p>
            <ul className={'list-disc list-inside pl-4 pb-8'}>
              <li>6 month subscription LTV</li>
              <li>6 month one time purchase LTV</li>
            </ul>
            <p>
              Because the additional LTV for subscribers accrues over a 6-month time horizon, we
              calculate sales lift for each monthly cohort. We adjust the total Incremental LTV
              based on the number of months that a cohort has been active so that we only account
              for sales lift that your brand has already realized.{' '}
            </p>
          </>
        )}
      </div>
      <div className={'pb-4'}>
        <div className={'mt-7'}>
          <BeamMetricInputBox
            loading={roiResponse.loading}
            data={[
              { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
              {
                type: MetricInputTypes.metric,
                label: 'Incremental First Subscriptions Attributed to Beam',
                value: (
                  round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0
                ).toString(),
                tooltip: `The number of additional subscribers Beam converted, calculated by multiplying Total Carts by ${user?.partner.name}'s Subscriber Signup Lift`,
                icon: <UpDownIcon />,
              },
              { type: MetricInputTypes.header, label: `${user?.partner.name}'s Data` },
              {
                type: MetricInputTypes.metric,
                label: '6 month subscription LTV',
                value: dollarFormat(dataWikiData?.sixMonthSubscriptionLtv || null),
                tooltip: `Lifetime customer value of ${user?.partner.name}'s subscribers over a 6 month period`,
              },
              ...(isSubscriptionOnly
                ? []
                : [
                    {
                      type: MetricInputTypes.metric,
                      label: '6 month one time purchase LTV',
                      value: dollarFormat(dataWikiData?.sixMonthNonSubscriptionLtv || null),
                      tooltip: `Lifetime customer value of ${user?.partner.name}'s customers making one-time purchases over a 6 month period`,
                    },
                  ]),
            ]}
          />
        </div>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>How do we project the additional revenue?</h3>
        <p>
          For incremental first subscriptions attributed to beam in a given cohort, we estimate how
          much incremental LTV the brand has captured for those customers in the time they have been
          active.
        </p>
      </div>
      <div className={'py-5'}>
        <CalculationBox
          loading={roiResponse.loading}
          title={'Calculation: Single Cohort'}
          operands={[
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Attributed to Beam',
            },
            { value: SIX_MONTH_DURATION_VALUE, description: 'Months this Cohort is Active' },
            {
              value: SIX_MONTH_DURATION_VALUE,
              description: (
                <>
                  <span className={'whitespace-nowrap'}>6-Month</span> Period
                </>
              ),
            },
            {
              value: dollarFormat(dataWikiData?.incrementalLtv, 0),
              description: isSubscriptionOnly
                ? '6 Month LTV per subscriber'
                : 'Incremental LTV per subscriber vs one time customer LTV',
            },
            {
              value: dollarFormat(oneMonthMetrics?.subscriberSignUpSalesLift),
              description: '6 Month Sales Lift for One Monthly Cohort',
            },
          ]}
          operators={['X', '/', 'X', '=']}
        />
        <div className={cx($$.paragraphBlock)}>
          <p>
            <em>
              Assumption: The value of the subscription will evenly accrue throughout the 6 month
              period (1/6 of the 6 month value for each of the first 6 months they are live)
            </em>
          </p>
        </div>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>
          How do we measure the total 6-month sales lift from increased subscriber conversion?
        </h3>
        <p>
          We apply the single cohort sales lift calculation to each of the cohorts from the 6-month
          period. Adding the lift from each individual cohort, we calculate the full 6-month sales
          lift your brand has realized. To be extra conservative, we do not account for the future
          sales lift that recent cohorts may help drive.
        </p>
      </div>

      <div className={'pt-5'}>
        <CalculationTable
          title={'Calculation: 6 Month Sales Lift Impact'}
          data={roiResponse.data}
          loading={roiResponse.loading}
        />
      </div>
    </article>
  )
}
