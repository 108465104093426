import cx from 'classnames'
import { ReactNode } from 'react'

interface CenteredContentProps {
  children: ReactNode
  /**
   * Use `100vh` instead of `100%` for height
   */
  useVH?: boolean
}

// Simple component that fills full width and height of its parent and centers the content
export const CenteredContent = ({ children, useVH = false }: CenteredContentProps) => {
  return (
    <div
      className={cx('flex items-center justify-center w-full h-full', {
        'h-full': !useVH,
        'h-screen': useVH,
      })}>
      {children}
    </div>
  )
}
