import { ReactNode, useEffect } from 'react'

import { useBeamSelector } from '../../../hooks'
import { TUser } from '../../../utils/types'
import { getGtagTrackingId } from './googleAnalyticsHelpers'

/**
 * Base "gtag" function for Google Analytics
 * @docs https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * @example baseGtag('config', <GA_ID>);
 */
export function baseGtag(..._args: any[]) {
  window.dataLayer = window.dataLayer || []
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments)
}

/**
 * Component to inject Google Analytics code into DOM
 */
export const BeamGoogleAnalytics = ({ children }: { children?: ReactNode }) => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)

  useEffect(() => {
    // prevent mounting gtag when session partner hasn't been set or if it's an admin user
    if (!user?.chainId || !user?.id) return

    const ga_tracking_id = getGtagTrackingId()

    if (window.location.hostname.includes('localhost')) {
      return
    }

    const scriptSrc = `https://www.googletagmanager.com/gtag/js?id=${ga_tracking_id}`
    const existingScript = document.querySelector(`script[src="${scriptSrc}"]`)

    if (existingScript) {
      return // do not set up GA twice
    }

    const scriptElement = document.createElement('script')

    scriptElement.src = scriptSrc
    scriptElement.async = true
    document.head.appendChild(scriptElement)

    baseGtag('js', new Date())
    // https://developers.google.com/analytics/devguides/collection/ga4/event-parameters?client_type=gtag#set-up-every-event
    baseGtag('set', 'user_properties', { chain_id: user.chainId, beam_user_id: user.id })
    baseGtag('config', ga_tracking_id, {
      beam_user_id: user.id,
      chain_id: user.chainId,
    })
  }, [user?.chainId, user?.id])

  return <>{children}</>
}
