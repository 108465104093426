import { isNil } from 'lodash'
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useUrlSearchParams } from '../../../hooks/useUrlSearchParams'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCheckbox } from '../../../stories/BeamCheckbox/BeamCheckbox'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CenteredContent } from '../CenteredContent'

enum OnboardingParamKeys {
  ONBOARDING_STEP = 'onboarding_step',
  REQUEST_ID = 'shopify_onboarding_request_id',
}
enum OnboardingStepParamValues {
  CONTACT = 'contact',
  AWAITING_APPROVAL = 'awaiting_approval',
}
interface ContactInfo {
  name: string
  email: string
  phone: string
  tosAccepted: boolean
}
interface OnboardingRequestBody {
  shopifyOnboardingRequestId: string
  contact: {
    email?: string
    phone?: string
    name: string
  }
}
type FormStatus = { status: 'submitted' | 'error'; message?: string } | null

export const ShopifyInstallPage = () => {
  const [formStatus, setFormStatus] = useState<FormStatus>(null)
  const { queryParams } = useUrlSearchParams()
  const onboardingStep = queryParams.get(OnboardingParamKeys.ONBOARDING_STEP)

  if (onboardingStep === OnboardingStepParamValues.AWAITING_APPROVAL) {
    return (
      <CenteredContent useVH>
        <CenteredMessageWrapper>
          <div className={'mb-4'}>
            <BeamLogo />
          </div>
          <h2 className={'font-secondary'}>A Beam admin will be in contact soon.</h2>
        </CenteredMessageWrapper>
      </CenteredContent>
    )
  }

  if (formStatus?.status === 'submitted') {
    return (
      <CenteredContent useVH>
        <CenteredMessageWrapper>
          <div className={'mb-4'}>
            <BeamLogo />
          </div>

          <h2 className={'font-secondary m-0'}>
            Thank you for your interest in Beam!
            <br />A Beam admin will be in contact soon.
          </h2>
        </CenteredMessageWrapper>
      </CenteredContent>
    )
  }

  return (
    <>
      <BeamToast
        variant={'error'}
        open={!isNil(formStatus) && formStatus.status === 'error'}
        text={formStatus?.message || ''}
        onClose={() => {
          setFormStatus(null)
        }}
      />

      <ContactForm setFormStatus={setFormStatus} />
    </>
  )
}

const ContactForm = ({
  setFormStatus,
}: {
  setFormStatus: Dispatch<SetStateAction<FormStatus>>
}) => {
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    name: '',
    email: '',
    phone: '',
    tosAccepted: false,
  })
  const { queryParams } = useUrlSearchParams()
  const requestId = queryParams.get(OnboardingParamKeys.REQUEST_ID)

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'tosAccepted') {
      setContactInfo(prev => ({ ...prev, tosAccepted: !prev.tosAccepted }))
      return
    }

    setContactInfo(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  async function handleSubmit(e: any) {
    e.preventDefault()

    try {
      const requestBody: OnboardingRequestBody = {
        shopifyOnboardingRequestId: requestId || '',
        contact: contactInfo,
      }
      const res = await axiosRequest(
        'POST',
        `${getV2ApiUrl()}/shopify-public-app/add-onboarding-request-contact`,
        requestBody
      )

      if (res.status === 200) {
        setFormStatus({
          status: 'submitted',
        })
      }
    } catch (error: any) {
      setFormStatus({
        status: 'error',
        message: 'There was a problem submitting your information. Please try again.',
      })
    }
  }

  const allInputsFilled = Object.values(contactInfo).every(val => Boolean(val))

  return (
    <div className={'flex flex-col justify-center items-center w-1/3 h-screen m-auto'}>
      <div className={'mb-4'}>
        <BeamLogo />
      </div>

      <form onSubmit={handleSubmit}>
        <div className={'space-y-2'}>
          <BeamTextfield
            name={'name'}
            label={'Name'}
            variant={'small'}
            type={'text'}
            onChange={handleOnChange}
          />
          <BeamTextfield
            name={'email'}
            label={'Email'}
            type={'email'}
            variant={'small'}
            onChange={handleOnChange}
          />
          <BeamTextfield
            name={'phone'}
            label={'Phone number'}
            variant={'small'}
            type={'tel'}
            onChange={handleOnChange}
          />
        </div>
        <div className={'pt-5'}>
          <BeamCheckbox
            name={'tosAccepted'}
            checked={contactInfo.tosAccepted}
            onChange={handleOnChange}>
            <span className={'text-sm'}>
              By checking this box, I acknowledge that I have read, understood, and agree to Beam
              Impact&apos;s{' '}
              <a
                href={'https://www.beamimpact.com/terms'}
                target={'_blank'}
                rel={'noopener noreferrer'}>
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://www.beamimpact.com/privacy-policy'}
                target={'_blank'}
                rel={'noopener noreferrer'}>
                Privacy Policy
              </a>
              .
            </span>
          </BeamCheckbox>
        </div>
        <div className={'flex justify-center w-full pt-5'}>
          <BeamButton
            type={'submit'}
            variant={'basic'}
            label={'Submit'}
            className={'w-1/2'}
            disabled={!allInputsFilled}
          />
        </div>
        <div className={'text-center text-sm pt-4'}>
          Already have an account?{' '}
          <Link
            to={{
              pathname: `${REDESIGN_ROOT_PATH}/login`,
              state: {
                from: {
                  pathname: window.location.pathname,
                },
              },
            }}>
            Sign In
          </Link>
        </div>
      </form>
    </div>
  )
}

const CenteredMessageWrapper = ({ children }: { children: ReactNode }) => {
  return <div className={'flex flex-col items-center text-center w-1/3'}>{children}</div>
}
